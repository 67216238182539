import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { ApiDataStrategy as TApiDataStrategy } from 'o365.pwa.declaration.sw.strategies.api.data.strategy.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiDataStrategy } = self.o365.importScripts<{ ApiDataStrategy: typeof TApiDataStrategy }>("o365.pwa.modules.sw.strategies.api.data.strategy.ts");

    function initializeApiDataRouteHandler() {
        self.o365.registerRoute(
            new RegExp('\/nt(?:\/api\/data)(?!\/export)(?:\/.*)?'),
            new ApiDataStrategy({}),
            'POST'
        );
    }
    
    self.o365.exportScripts({ initializeApiDataRouteHandler });
})();
